<template>
  <div :style="{ 'min-height': sheight + 'px' }">
    <el-row class="tac" style="margin-bottom: 80px">
      <el-col :span="5">
        <el-menu
          class="el-menu-vertical-demo"
          text-color="#111111"
          :default-active="tabindex"
          active-text-color="#00BCFF"
          @open="handleOpen"
          @close="handleClose"
          unique-opened
          style="width: 240px"
        >
          <el-menu-item
            v-for="(item, index) in questionList"
            :key="index"
            @click="gotonext(item, index)"
          >
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="19">
        <div class="center-content" style="padding-left: 10px">
          <el-card class="box-card" style="margin-bottom: 130px">
            <div class="tit font18">
              {{ name }}
            </div>
            <div
              class="content-all"
              v-for="(item, index) in contentList"
              :key="index"
            >
              <div class="c-tit">
                {{ item.questionName }}
              </div>
              <div class="c-main">
                {{ item.answerWord }}
              </div>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      tabindex: "1",
      sheight: "",
      questionList: [],
      name: "",
      contentList: [],
    };
  },
  created() {
    this.sheight = document.documentElement.scrollHeight - 80;
    this.getQuestionList();
  },
  mounted() {},
  methods: {
    getQuestionList() {
      this.$api.getQuestionList("get").then((res) => {
        // console.log(res);
        this.questionList = res.data;
        this.contentList = res.data[0].list;
        this.name = res.data[0].name;
      });
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    gotonext(data, index) {
      // console.log('进入了跳转',path,this.$route.path)
      this.tabindex = toString(index + 1);
      this.name = data.name;
      this.contentList = data.list;
      // this.$router.push('/deliveryrecord');
    },
    handleCommand(command) {
      switch (command) {
        case "logout":
          this.loginout();
          break;
      }
    },
    loginout() {
      window.sessionStorage.clear;

      this.$router.push("/login");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-card {
  border-radius: 0px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
}
/deep/.el-card__body {
  padding: 20px 0px 40px 0px;
}
.tit {
  color: #111111;
  padding-bottom: 20px;
  padding-left: 30px;
  border-bottom: 1px solid #f3f3f3;
}

.content-all {
  padding: 0 30px;
  .c-tit {
    color: #ff4444;
    margin-top: 30px;
    font-weight: 600;
  }
  .c-main {
    margin-top: 20px;
    color: #111111;
    letter-spacing: 1px;
  }
}
/deep/.el-submenu,
/deep/.el-menu-item {
  padding: 0;
}
// /deep/.is-active{
// 	position: relative;
// }
// /deep/.is-active :after{
// 	position: absolute;
// 	content: '';
// 	width: 4px;
// 	height: 12px;
// 	bottom: 50%;
// 	background: #00BCFF;
// 	left: 2px;
// 	transform: translate(-50%, 60%);
// }
.tebie /deep/.el-menu-item {
  padding-left: 20px !important;
  color: #666666 !important;
}
.tebie /deep/.is-active {
  color: #00bcff !important;
}

.content {
  padding: 20px 0 200px 0;
}
.center-content {
  // height: 600px;
  // background-color: #0000FF;
}

.el-header {
  background-color: #ffffff;
  color: #333;
  text-align: center;
  height: 56px !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1) !important;
}
.el-footer {
  height: 352px !important;
  padding: 0 0 !important;
  margin: 0 0 !important;
}
.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #f3f3f3;
  margin-top: 56px;
  padding: 0;
}
</style>
